import { createBrowserRouter } from 'react-router-dom'
import { NotFoundPage } from './pages/NotFoundPage'

export const router = createBrowserRouter([
  // Ticket is denormalized and has a HASH index of ticketId.  Only needs react-router and the U.I.
  // We separate it from everything else in the app
  { path: '/ticket/:ticketId', lazy: () => import('./pages/ticket.$ticketId') },
  { path: '/sendemails', lazy: () => import('./pages/send-emails') },
  {
    path: '/',
    lazy: () => import('./pages/_index'),
    children: [
      { path: '', lazy: () => import('./pages/index') },
      { path: 'features', lazy: () => import('./pages/features') },
      { path: 'my-tickets', lazy: () => import('./pages/my-tickets') },
      { path: 'getting-started', lazy: () => import('./pages/getting-started') },
      {
        path: 'admin',
        children: [
          { path: '', lazy: () => import('./pages/admin') },
          { path: 'create-organization', lazy: () => import('./pages/admin.create-new-organization') },
          { path: 'su', children: [
            { path: '', lazy: () => import('./pages/admin.su') },
          ]},
          {
            path: ':oid',
            lazy: () => import('./pages/admin._$oid'),
            children: [
              { path: '', lazy: () => import('./pages/admin.$oid') },
              { path: 'overview',
                children: [
                  { path: '', lazy: () => import('./pages/admin.$oid.overview') },
                  { path: 'transactions', lazy: () => import('./pages/admin.$oid.overview.transactions')}
                ]
              },
              { path: 'checkin',
                lazy: () => import('./pages/admin.$oid._checkin'),
                children: [
                  { path: '', lazy: () => import('./pages/admin.$oid.checkin') },
                  { path: 'attendees', lazy: () => import('./pages/admin.$oid.checkin.attendees') },
                  { path: 'events', lazy: () => import('./pages/admin.$oid.checkin.events') },
                  { path: 'tasks', lazy: () => import('./pages/admin.$oid.checkin.tasks') },

                ]
              },
              { path: 'events',
                children: [
                  { path: '', lazy: () => import('./pages/admin.$oid.events') },
                  { path: 'create', lazy: () => import('./pages/admin.$oid.events.create') },
                      {
                        path: ':pid',
                        lazy: () => import('./pages/admin.$oid.events.$pid'),
                        children: [
                          { path: 'schedule', lazy: () => import('./pages/admin.$oid.events.$pid.schedule') },
                          { path: 'settings', lazy: () => import('./pages/admin.$oid.events.$pid.settings') },
                          { path: 'scan', lazy: () => import('./pages/admin.$oid.events.$pid.scan') },
                          { path: 'attendees', lazy: () => import('./pages/admin.$oid.events.$pid.attendees') },
                          { path: 'analytics', lazy: () => import('./pages/admin.$oid.events.$pid.analytics') },
                        ],
                      },
                ]
              },
              { path: 'staff', children: [
                { path: '', lazy: () => import('./pages/admin.$oid.staff') },
                { path: 'create', lazy: () => import('./pages/admin.$oid.staff.create') }
                ],
              },
              {
                path: 'scan',
                lazy: () => import('./pages/admin.$oid.scan'),
              },
              {
                path: 'profile',
                children: [
                  { path: '', lazy: () => import('./pages/admin.$oid.profile') },
                  { path: 'settings', lazy: () => import('./pages/admin.$oid.profile.settings') },
                ],
              },
              {
                path: 'offerings',
                children: [
                  { path: '', lazy: () => import('./pages/admin.$oid.offerings') },
                  {
                    path: 'contests',
                    children: [
                      { path: '', lazy: () => import('./pages/admin.$oid.offerings.contests') },
                      { path: 'create', lazy: () => import('./pages/admin.$oid.offerings.contests.create') },
                      {
                        path: ':pid',
                        lazy: () => import('./pages/admin.$oid.offerings.contests.$pid'),
                        children: [
                          { path: 'contestants', lazy: () => import('./pages/admin.$oid.offerings.contests.$pid.contestants') },
                          { path: 'settings', lazy: () => import('./pages/admin.$oid.offerings.contests.$pid.settings') },
                          { path: 'voting', lazy: () => import('./pages/admin.$oid.offerings.contests.$pid.voting') },
                          // { path: 'contestants', lazy: () => import('./pages/admin.$oid.offerings.contests.$pid.contestants') },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'festivals',
                    children: [
                      { path: '', lazy: () => import('./pages/admin.$oid.offerings.festivals') },
                      { path: 'create', lazy: () => import('./pages/admin.$oid.offerings.festivals.create') },
                      {
                        path: ':pid',
                        lazy: () => import('./pages/admin.$oid.offerings.festivals.$pid'),
                        children: [
                          { path: 'schedule', lazy: () => import('./pages/admin.$oid.offerings.festivals.$pid.schedule') },
                          { path: 'settings', lazy: () => import('./pages/admin.$oid.offerings.festivals.$pid.settings') },
                          { path: 'scan', lazy: () => import('./pages/admin.$oid.offerings.festivals.$pid.scan') },
                          { path: 'attendees', lazy: () => import('./pages/admin.$oid.offerings.festivals.$pid.attendees') },
                          { path: 'analytics', lazy: () => import('./pages/admin.$oid.offerings.festivals.$pid.analytics') },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'dances',
                    children: [
                      { path: '', lazy: () => import('./pages/admin.$oid.offerings.dances') },
                      { path: 'create', lazy: () => import('./pages/admin.$oid.offerings.dances.create') },
                      {
                        path: ':pid',
                        lazy: () => import('./pages/admin.$oid.offerings.dances._$pid'),
                        children: [
                          { path: '', lazy: () => import('./pages/admin.$oid.offerings.dances.$pid') },
                          { path: 'scan', lazy: () => import('./pages/admin.$oid.offerings.dances.$pid.scan') },
                          { path: 'attendees', lazy: () => import('./pages/admin.$oid.offerings.dances.$pid.attendees') },
                          { path: 'analytics', lazy: () => import('./pages/admin.$oid.offerings.dances.$pid.analytics') },
                          { path: 'history', lazy: () => import('./pages/admin.$oid.offerings.dances.$pid.history') },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'classes',
                    children: [
                      { path: '', lazy: () => import('./pages/admin.$oid.offerings.classes') },
                      { path: 'create', lazy: () => import('./pages/admin.$oid.offerings.classes.create') },
                      {
                        path: ':pid',
                        lazy: () => import('./pages/admin.$oid.offerings.dances._$pid'),
                        children: [
                          { path: '', lazy: () => import('./pages/admin.$oid.offerings.classes.$pid') },
                          { path: 'scan', lazy: () => import('./pages/admin.$oid.offerings.classes.$pid.scan') },
                          { path: 'attendees', lazy: () => import('./pages/admin.$oid.offerings.classes.$pid.attendees') },
                          { path: 'analytics', lazy: () => import('./pages/admin.$oid.offerings.classes.$pid.analytics') },
                          { path: 'history', lazy: () => import('./pages/admin.$oid.offerings.classes.$pid.history') },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'store',
        children: [
          { path: '', lazy: () => import('./pages/store') },
          {
            path: ':oid',
            lazy: () => import('./pages/store._$oid'),
            children: [
              { path: '', lazy: () => import('./pages/store.$oid') },
              { path: 'calendar', lazy: () => import('./pages/store.$oid.calendar') },
              { path: 'profile', children: [
                { path: '', lazy: () => import('./pages/store.$oid.profile') },
                { path: 'tickets', children: [
                  { path: '',  lazy: () => import('./pages/store.$oid.profile.tickets') },
                  { path: ':tid',  lazy: () => import('./pages/store.$oid.profile.tickets.$tid') },
                ]},
                { path: 'membership', lazy: () => import('./pages/store.$oid.profile.membership') }
              ]
            },
              {
                path: 'classes',
                children: [
                  { path: '', lazy: () => import('./pages/store.$oid.classes') },
                  {
                    path: ':pid',
                    children: [
                      { path: '', lazy: () => import('./pages/store.$oid.classes.$pid')},
                      { path: 'purchase', lazy: () => import('./pages/store.$oid.classes.$pid.purchase') },
                    ]
                  },
                ],
              },
              {
                path: 'dances',
                children: [
                  { path: '', lazy: () => import('./pages/store.$oid.dances') },
                  {
                    path: ':pid',
                    children: [
                      { path: '', lazy: () => import('./pages/store.$oid.dances.$pid')},
                      { path: 'purchase', lazy: () => import('./pages/store.$oid.dances.$pid.purchase') },
                    ]
                  },
                ],
              },
            ],
          },
        ],
      },
    ]
  },
  { path: '*', element: <NotFoundPage goBackPath='/' goBackText='Go back home' /> },
]) as any
